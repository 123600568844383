@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap');

html {
  font-family: 'ABeeZee', sans-serif;
}

.react-date-picker__wrapper {
  @apply p-3 rounded-lg
}